const DashboardUtils = {
  getLanguage() {
    let language = localStorage.getItem("language");
    language = language === "ro" ? "ro-RO" : "en-US";
    return language;
  },
  today(date) {
    // let date = this.globalPrediction.today.date;
    return new Date(date.year, date.month - 1, date.day).toLocaleString(
      this.getLanguage(),
      {
        day: "numeric",
        month: "long",
        year: "numeric",
      }
    );
  },
  thisMonth(date) {
    // let x = this.globalPrediction.month.stats.x[0];
    if (!date) return;
    return new Date(date.year, date.month - 1, date.day).toLocaleString(
      this.getLanguage(),
      { month: "long" }
    );
  },
  thisYear() {
    return new Date().toLocaleString(this.getLanguage(), { year: "numeric" });
  },
  getTodayChartOptions(yesterday, today) {
    return {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      colors: this.chartColors,
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [yesterday, today],
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return Number(val).toLocaleString();
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (value) {
            return Number(value).toLocaleString() + " MW";
          },
        },
      },
    };
  },
  getMonthChartOptions(globalPrediction, title) {
    return {
      chart: {
        title: {
          text: title,
        },
        type: "line",
        toolbar: {
          show: false,
        },
      },
      colors: this.chartColors,
      xaxis: {
        type: "datetime",
        labels: {
          formatter: (_, timestamp) => {
            return new Date(timestamp).toLocaleString(this.getLanguage(), {
              month: "short",
              day: "numeric",
            });
          },
        },
      },
      yaxis: {
        show: true,
        showAlways: true,
        title: {
          text: title,
        },
        labels: {
          formatter: function (val) {
            return Number(val).toLocaleString();
          },
        },
      },
      annotations: {
        xaxis: this.getWeekends(globalPrediction),
        points: this.eventsAnnotations(globalPrediction),
      },
      tooltip: {
        x: {
          formatter: (value) => {
            return new Date(value).toLocaleString(this.getLanguage(), {
              weekday: "short",
              year: "numeric",
              month: "short",
              day: "numeric",
            });
          },
        },
        y: {
          formatter: function (value) {
            return Number(value).toLocaleString() + " MW";
          },
        },
      },
    };
  },
  getWeekends(globalPrediction) {
    // debugger; //eslint-disable-line
    let points = [];
    for (let i = 0; i < globalPrediction.month.stats.x.length; i++) {
      let data = globalPrediction.month.stats.x[i];
      let data2 = globalPrediction.month.stats.x[i + 1];
      if (!data2) {
        data2 = data;
      }
      let day = new Date(data.year, data.month - 1, data.day).getDay();
      if (day == 6) {
        // saturday
        points.push({
          x: new Date(data.year, data.month - 1, data.day).getTime(),
          x2: new Date(data2.year, data2.month - 1, data2.day).getTime(),
          fillColor: "#a4acb3",
          opacity: 0.4,
        });
      }
    }
    return points;
  },
  eventsAnnotations(globalPrediction) {
    let points = [];
    for (let i = 0; i < globalPrediction.month.stats.events.length; i++) {
      let value = globalPrediction.month.stats.events[i];
      let x = new Date(
        value[0].year,
        value[0].month - 1,
        value[0].day
      ).getTime();
      let y = value[1];
      points.push({
        x: x,
        y: y,
        marker: {
          size: 6,
          fillColor: "white",
          strokeColor: this.colors[value[2]], // TODO: depending on type (value[2])
          radius: 2,
        },
        label: {
          borderColor: this.colors[value[2]],
          style: {
            color: "#fff",
            background: this.colors[value[2]],
          },
          text: value[2],
        },
      });
    }
    return points;
  },
  getMonthSeries(globalPrediction, title) {
    let series = [];
    for (let i = 0; i < globalPrediction.month.stats.x.length; i++) {
      let x = globalPrediction.month.stats.x[i];
      let y = globalPrediction.month.stats.y[i];

      series.push([new Date(x.year, x.month - 1, x.day).getTime(), y]);
    }
    return [{ name: title, data: series }];
  },
  getTodaySeries(globalPrediction, yesterday, today) {
    return [
      {
        name: yesterday,
        data: [globalPrediction.today.stats[0]],
      },
      {
        name: today,
        data: [globalPrediction.today.stats[1]],
      },
    ];
  },
  getYearSeries(globalPrediction, real, predicted) {
    for (let i = 0; i < globalPrediction.year.stats.real.length; i++) {
      let valReal = globalPrediction.year.stats.real[i];
      globalPrediction.year.stats.real[i] = valReal;
    }

    for (let i = 0; i < globalPrediction.year.stats.predict.length; i++) {
      let valPredict = globalPrediction.year.stats.predict[i];
      globalPrediction.year.stats.predict[i] = valPredict;
    }

    return [
      {
        name: real,
        data: globalPrediction.year.stats.real,
      },
      {
        name: predicted,
        data: globalPrediction.year.stats.predict,
      },
    ];
  },
  getYearCategories(globalPrediction) {
    let abbrMonths = [];
    let months = globalPrediction.year.stats.x;
    for (let i = 0; i < months.length; i++) {
      abbrMonths.push(
        new Date(
          months[i].year,
          months[i].month - 1,
          months[i].day
        ).toLocaleString(this.getLanguage(), {
          month: "short",
        })
      );
    }
    return abbrMonths;
  },
  getYearChartOptions(globalPrediction, title) {
    return {
      chart: {
        type: "bar",
      },
      colors: this.chartColors,
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: this.getYearCategories(globalPrediction),
      },
      yaxis: {
        show: true,
        showAlways: true,
        title: {
          text: title,
        },
        labels: {
          formatter: function (val) {
            return Number(val).toLocaleString();
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (value) {
            return Number(value).toLocaleString() + " MW";
          },
        },
      },
    };
  },
  colors: {
    "Power outage": "#fc2635", // red
    "Bad weather": "#fd8226", // orange
    Wind: "#8126fc", // purple
    Other: "#26fc81", // green
    Holiday: "#fc26a0", // pink
    transparent: "transparent",
  },
  eventIcons: {
    "Power outage": "fa-solid fa-battery-half",
    "Bad weather": "fa-solid fa-cloud-rain",
    Wind: "fa-solid fa-wind",
    Other: "fa-solid fa-exclamation-triangle",
    Holiday: "fa-solid fa-umbrella-beach",
  },
  chartColors: ["#3699FF", "#8126fc", "#fd8226", "#757595"], // blue, purple, orange, gray
};

export default DashboardUtils;
